
import axios from 'axios';
import React from 'react';
import { Chart } from 'react-charts';
import { Duration } from 'luxon';

class DayAheadView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'dayAheadPrices': []
        }
        this.mounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.mounted) {
            if (prevProps.mapCode !== this.props.mapCode || prevProps.currency !== this.props.currency) {
                if (this.dayAheadRequest) {
                    this.dayAheadRequest.cancel();
                    this.dayAheadRequest = null;
                }
                this.loadData();
            }
        }
    }

    loadData() {
        const currency = this.props.currency;
        const areaMapCode = this.props.mapCode;
        if (typeof(areaMapCode) == 'undefined' || typeof(currency) == 'undefined') {
            this.dayAheadRequest = null;
            return;
        }
        const base_uri = 'https://electric.radiotube.org'
        const now = new Date();
        const start = new Date(now.getTime() - (24 * 3600 * 1000));
        const end = new Date(now.getTime() + (24 * 3600 * 1000));
        const path = '/v1/day-ahead-price/map-codes/'+areaMapCode+'/'+start.toISOString()+'/'+end.toISOString()
        const query = 'currency='+encodeURIComponent(currency);
        this.dayAheadRequest = axios.get(base_uri+path+'?'+query);
        this.dayAheadRequest.then(response => {
            this.dayAheadRequest = null;
            if (response.status == 200) {
                this.setState({'dayAheadPrices': response.data});
            } else {
                alert('Problem with fetching price data');
            }
        });
    }

    componentDidMount() {
        this.mounted = true;
        this.loadData();
    }
    componentWillUnmount() {
        this.mounted = false;
        if (this.dayAheadRequest) {
            this.dayAheadRequest.cancel();
            this.dayAheadRequest = null;
        }
    }
    render() {
        const now = (new Date()).getTime();
        var max = 0;
        const dataSerie = this.state.dayAheadPrices.map((record) => {
            const date = Date.parse(record.datetime);
            if (record.price > max) {
                max = record.price;
            }
            return {x: (date - now) / 3600000, y: record.price};
        });

        const data = [
                {
                    label: 'Day ahead price',
                    data: dataSerie
                },
                {
                    label: 'Now',
                    data: [{x: 0, y: 0}, {x: 0, y: max}]
                }
            ];


        const axes = [
                { primary: true, type: 'linear', position: 'bottom' },
                { type: 'linear', position: 'left' }
            ];

        return (
            <div>
                <div className={'priceChart'}>
                    <Chart data={data} axes={axes} />
                </div>
                <table className={'dayahead'}>
                    <thead>
                        <tr>
                            <th>{'Date and time'}</th>
                            <th>{'Duration'}</th>
                            <th>{'Price /MWh'}</th>
                        </tr>
                    </thead>
                    <tfoot></tfoot>
                    <tbody>
                    {
                        this.state.dayAheadPrices.map((record) => {
                            const startMillis = Date.parse(record.datetime);
                            const date = new Date(startMillis);
                            const duration = Duration.fromISO(record.resolution);
                            const endMillis = startMillis + duration.as('milliseconds');
                            const match = (startMillis <= now && endMillis > now);
                            return (
                                <tr className={match ? 'current' : null}>
                                    <td>{date.toDateString()} {date.toTimeString()}</td>
                                    <td>{duration.toISO()}</td>
                                    <td>{record.price}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default DayAheadView;
