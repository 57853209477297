
import axios from 'axios';
import React from 'react';

const defaultMapCode = 'NO4';

const countryTranslations = {
    'en': {
        'AT': 'Austria',
        'CH': 'Switzerland',
        'CZ': 'Czech Republic',
        'DK': 'Denmark',
        'EE': 'Estonia',
        'ES': 'Spain',
        'FI': 'Finland',
        'GB': 'Great Britain',
        'GR': 'Greece',
        'HU': 'Hungary',
        'LT': 'Lithuania',
        'LV': 'Latvia',
        'ME': 'Montenegro',
        'NO': 'Norway',
        'PT': 'Portugal',
        'RO': 'Romania',
        'SE': 'Sweden',
        'SI': 'Slovenia',
        'SK': 'Slovakia',
        'BE': 'Belgium',
        'DE': 'Germany',
        'FR': 'France',
        'IE': 'Ireland',
        'IT': 'Italy',
        'NL': 'Netherlands',
        'PL': 'Poland',
        'BG': 'Bulgaria',
        'RS': 'Serbia',
        'HR': 'Croatia',
        'UA': 'Ukraine'
    }
};
const mapCodeLabels = {
    'NO1': 'NO1 Oslo',
    'NO2': 'NO2 Kristiansand',
    'NO3': 'NO3 Molde, Trondheim',
    'NO4': 'NO4 Tromsø',
    'NO5': 'NO5 Bergen'
}

class SelectMapCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'mapCodes': [],
            'territoryMapCodes': [],
            'selectedTerritory': null,
            'selectedMapCode': undefined
        };
        this.territoryRef = React.createRef();
        this.codeRef = React.createRef();
    }
    componentDidMount() {
        const base_uri = 'https://electric.radiotube.org'
        this.mapCodesRequest = axios.get(base_uri+'/v1/day-ahead-price/map-codes');
        this.mapCodesRequest.then((response) => {
            this.mapCodesRequest = null;
            if (response.status == 200) {
                const allMapCodes = response.data;
                const territories = this.getTerritories(allMapCodes);
                var territory = this.state.selectedTerritory || (territories.length > 0 ? territories[0] : undefined);
                var selectedCode = undefined;
                for (var i in territories) {
                    var terr = territories[i];
                    const cds = this.getMapCodes(allMapCodes, terr);
                    for (var j in cds) {
                        if (cds[j] == defaultMapCode) {
                            selectedCode = cds[j];
                            territory = terr;
                        }
                    }
                }
                const mapCodes = territory !== undefined ? this.getMapCodes(allMapCodes, territory) : [];
                if (selectedCode === undefined) {
                    selectedCode = mapCodes === null && mapCodes.length > 0 ? mapCodes[0] : undefined;
                }
                this.setState({'mapCodes': allMapCodes, 'selectedTerritory': territory, 'territoryMapCodes': mapCodes, 'selectedMapCode': selectedCode});
                this.handleSelected(selectedCode);
            }
        });
    }
    componentWillUnmount() {
        if (this.mapCodesRequest) {
            this.mapCodesRequest.cancel();
            this.mapCodesRequest = null;
        }
    }

    handleSelected(selectedCode) {
        if (typeof(this.props.onSelected) != 'undefined') {
            this.props.onSelected(selectedCode);
        }
    }

    getCatMapCodes(allMapCodes) {
        var cats = {};
        for (const i in allMapCodes) {
            const mapCode = allMapCodes[i];
            if (mapCode.length >= 2) {
                const country = mapCode.substr(0, 2);
                if (!cats.hasOwnProperty(country)) {
                    cats[country] = [];
                }
                cats[country][cats[country].length] = mapCode;
            }
        }
        return cats;
    }
    getMapCodes(allMapCodes, selectedTerritory) {
        var mapCodes = [];
        const catMapCodes = this.getCatMapCodes(allMapCodes);
        for (const i in allMapCodes) {
            if (!allMapCodes.hasOwnProperty(i)) {
                continue;
            }
            var foundCatName = "UND";
            const mapCode = allMapCodes[i];
            for (const catName in catMapCodes) {
                const catCodes = catMapCodes[catName];
                const found = catCodes.filter((code) => { return mapCode == code; }).length > 0;
                if (found) {
                    foundCatName = catName;
                    break;
                }
            }
            if (selectedTerritory == foundCatName) {
                mapCodes[mapCodes.length] = mapCode;
            }
        }
        return mapCodes;
    }
    selectedTerritory(territory) {
        const mapCodes = this.getMapCodes(this.state.mapCodes, territory);
        const selectedCode = mapCodes.length > 0 ? mapCodes[0] : undefined;
        this.setState({'selectedTerritory': territory, 'territoryMapCodes': mapCodes, 'selectedMapCode': selectedCode});
        this.handleSelected(selectedCode);
    }
    getTerritories(allMapCodes) {
        var territory = {
        };
        const catMapCodes = this.getCatMapCodes(allMapCodes);
        for (const i in allMapCodes) {
            if (!allMapCodes.hasOwnProperty(i)) {
                continue;
            }
            var foundCatName = "UND";
            const mapCode = allMapCodes[i];
            for (const catName in catMapCodes) {
                const catCodes = catMapCodes[catName];
                const found = catCodes.filter((code) => { return mapCode == code; }).length > 0;
                if (found) {
                    foundCatName = catName;
                    break;
                }
            }
            if (!territory.hasOwnProperty(foundCatName)) {
                territory[foundCatName] = [];
            }
            territory[foundCatName][territory[foundCatName].length] = mapCode;
        }
        var territories = []
        for (const catName in territory) {
            if (territory.hasOwnProperty(catName)) {
                territories[territories.length] = catName;
            }
        }
        return territories;
    }

    handleUpdatedCode() {
        const mapCode = this.codeRef.current.value;
        this.setState({'selectedMapCode': mapCode});
        this.handleSelected(mapCode);
    }

    render() {
        const locale = 'en';
        const territories = this.getTerritories(this.state.mapCodes);
        return (
            <span>
                <select value={this.state.selectedTerritory || (territories.length > 0 ? territories[0] : undefined)} ref={this.territoryRef} onChange={() => {this.selectedTerritory(this.territoryRef.current.value)}}>
                    {
                        territories.map((t) => {
                            let translation = countryTranslations[locale];
                            if (translation === undefined) {
                                translation = countryTranslations['en'];
                            }
                            let label = undefined;
                            if (translation !== undefined) {
                                label = translation[t];
                            }
                            if (label === undefined) {
                                label = t;
                            }
                            return (
                                <option value={t}>{label}</option>
                            );
                        })
                    }
                </select>
                <select value={this.state.selectedMapCode} ref={this.codeRef} onChange={() => { this.handleUpdatedCode(); }}>
                    {
                        this.state.territoryMapCodes.map((mapCode) => {
                            let label = mapCodeLabels[mapCode];
                            if (label === undefined) {
                                label = mapCode;
                            }
                            return (
                                <option value={mapCode}>{label}</option>
                            );
                        })
                    }
                </select>
            </span>
        )
    }
}

export default SelectMapCode;