import './App.css';
import React from 'react';
import DayAheadView from "./DayAheadView";
import SelectMapCode from "./SelectMapCode";
import CurrencySelect from "./CurrencySelect";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'mapCode': undefined,
            'currency': undefined
        }
    }
    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <p>
                        Day ahead prices
                    </p>
                </header>
                <div>
                    <p>Based on day ahead prices provided by <a
                        href={'https://transparency.entsoe.eu/'}>transparency.entsoe.eu</a> and currency conversion
                        rates by <a
                            href={"https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/index.en.html"}>European
                            Central Bank</a>.</p>
                    Area: <SelectMapCode mapCode={this.state.mapCode} onSelected={(mapCode) => { this.setState({'mapCode': mapCode}); }}/>
                    Currency: <CurrencySelect currency={this.state.currency} onSelect={(currency) => { this.setState({'currency': currency}); }} />
                    <DayAheadView mapCode={this.state.mapCode} currency={this.state.currency}/>
                </div>
            </div>
        );
    }
}

export default App;
