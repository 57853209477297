import React from 'react';

class CurrencySelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'currency': props.currency || 'NOK'
        };
        if (props.onSelect !== undefined) {
            props.onSelect(this.state.currency);
        }
        this.currencyRef = React.createRef();
    }

    handleCurrency() {
        const currency = this.currencyRef.current.value;
        if (this.props.onSelect !== undefined) {
            this.setState({'currency': currency});
            this.props.onSelect(currency);
        }
    }

    render() {
        const currencies = [
            'DKK',
            'EUR',
            'GBP',
            'NOK',
            'SEK',
            'USD'
        ];
        return (
            <select value={this.state.currency} ref={this.currencyRef} onChange={() => {this.handleCurrency();}}>
                {
                    currencies.map((currency) => {
                        return (
                            <option value={currency}>{currency}</option>
                        )
                    })
                }
            </select>
        );
    }
}

export default CurrencySelect;
